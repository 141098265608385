import { useCallback, useEffect, useState } from "react";
import ChatBox from "./ChatBox";
import { v4 as uuidv4 } from "uuid";
import { MessageService } from "../services/chat.service";
import { Message } from "../types/Message";
import { SESSION_KEY } from "../constants";
import { Cookies, useCookies } from "react-cookie";
import LoadingScreen from "./LoadingScreen";
import moment from "moment";
import { MessageRO } from "../types/MessageRO";
import { Agent } from "../types/Agent";
import { isMobileDevice } from "../utils";

const INITIAL_MESSAGE_LIMIT = 10;
const MESSAGE_INCREMENT = 10;

const Chat = ({
  isEmbedded,
  agentUuid,
}: {
  isEmbedded: boolean;
  agentUuid: string;
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [chatContainer, setChatContainer] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [agentInfo, setAgentInfo] = useState<Agent>();
  const [identifier, setIdentifier] = useState<string>();
  const [bottomSpacing, setBottomSpacing] = useState<number>(0);
  const [currentLimit, setCurrentLimit] = useState<number>(INITIAL_MESSAGE_LIMIT);
  const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(true);
  const [lastMessage, setLastMessage] = useState<Message>();
  const [dummyMessage, setDummyMessage] = useState<Message>();

  const [cookies, setCookie] = useCookies([SESSION_KEY]);

  useEffect(() => {
    if (!cookies[SESSION_KEY]) {
      console.debug("No identifier found, creating new one");
      generateNewIdentifier();
    } else {
      console.debug("Identifier found, using existing one");
      setIdentifier(cookies[SESSION_KEY]);
    }
  }, []);

  const generateNewIdentifier = useCallback(() => {
    let newIdentifier = uuidv4();
    setCookie(SESSION_KEY, newIdentifier, {
      path: "/",
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
    });
    setIdentifier(newIdentifier);
  }, []);

  useEffect(() => {
    const fetchAgentInfo = async (id: string) => {
      const infoResponse = await MessageService.getBotInfo(id);
      setAgentInfo(infoResponse.data);
      if (!isEmbedded) {
        document.title = infoResponse.data.name;
        let link = document.querySelector("link[rel~='icon']") as any;
        if (!link) {
          link = document.createElement("link") as any;
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href =
          infoResponse.data.botImgUrl ||
          `${process.env.REACT_APP_STATIC_FILE_PATH}/logo192.png`;
      }
    };
    if (agentUuid) {
      try {
        fetchAgentInfo(agentUuid);
      } catch (error) {
        console.error("Error fetching agent info:", error);
      }
    }
  }, [agentUuid]);

  const onUserMessageAdded = async (messageText: any) => {
    console.debug("message text: " + messageText);
    // add a dummy message to the chat to fake message sent, will be removed after durning next fetch
    let dummyId = "dummy-" + uuidv4();
    const dummyMessage: Message = {
      id: dummyId,
      role: "user",
      content: messageText,
      createdAt: moment().toISOString(),
      updatedAt: moment().toISOString(),
    };
    setDummyMessage(dummyMessage);
    await MessageService.sendMessages({
      bot_uuid: agentUuid, // Use extracted botUuid
      channel_type: "web",
      channel_id: agentUuid, // Use extracted botUuid
      thread_id: identifier,
      api_key: "94b9b5db9954ff5db891f8e8eea721f5",
      prompt: messageText,
    });
  };

  /**
   * Fetch messages from the server
   */
  const fetchMessages = useCallback(
    async () => {
      if (!agentUuid || !identifier) {
        console.error("Agent UUID or identifier not found");
        return;
      }
      
      try {
        let query = {
          bot_uuid: agentUuid, // Use extracted botUuid
          channel_type: "web",
          channel_id: agentUuid, // Use extracted botUuid
          thread_id: identifier,
          api_key: "94b9b5db9954ff5db891f8e8eea721f5",
          limit: currentLimit,
        };

        const response = await MessageService.getMessages(query);
        let transformedMesssages: Message[] = [];
        response?.data?.data?.forEach((message: MessageRO) => {
          if (message.userMsgId) {
            transformedMesssages.push({
              id: message.id,
              role: "user",
              content: message.userMsg || "",
              createdAt: message.createdAt,
              updatedAt: message.updatedAt,
            });
          }
          if (message.botMsgId) {
            transformedMesssages.push({
              id: message.id,
              role: "assistant",
              content: message.botMsg || "",
              createdAt: message.createdAt,
              updatedAt: message.updatedAt,
            });
          }
        });

        const newLastMessage = transformedMesssages[transformedMesssages.length - 1];

        if (lastMessage?.id !== newLastMessage?.id) {
          setDummyMessage(undefined);
          setLastMessage(newLastMessage);
        } else if (lastMessage?.role !== newLastMessage?.role) {
          setLastMessage(newLastMessage);
        }

        setMessages(transformedMesssages);
        setHasMoreMessages((response?.data?.data.length || 0) >= currentLimit);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    },
    [agentUuid, identifier, currentLimit, lastMessage]
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    const VISIBLE_INTERVAL = 2000; // 2 seconds
    const HIDDEN_INTERVAL = 15000; // 15 seconds
    let currentHeight = document.documentElement.clientHeight;

    const shouldUseVisibleInterval = () => {
      return !document.hidden && currentHeight > 0;
    };

    const startPolling = (interval: number) => {
      stopPolling();
      intervalId = setInterval(() => {
        try {
          fetchMessages();
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      }, interval);
    };

    const updatePollingInterval = () => {
      const interval = shouldUseVisibleInterval()
        ? VISIBLE_INTERVAL
        : HIDDEN_INTERVAL;
      console.debug(
        `Updating polling interval: ${interval}ms (visible: ${!document.hidden}, height: ${currentHeight})`
      );
      startPolling(interval);
    };

    const stopPolling = () => {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
    };

    // Handle visibility and height changes
    const handleVisibilityChange = () => {
      updatePollingInterval();
    };

    // Add resize observer to handle height changes
    const resizeObserver = new ResizeObserver((entries) => {
      const htmlElement = entries[0];
      if (htmlElement) {
        currentHeight = htmlElement.contentRect.height;
        updatePollingInterval();
      }
    });

    // Initial setup
    updatePollingInterval();
    document.addEventListener("visibilitychange", handleVisibilityChange);
    chatContainer && resizeObserver.observe(chatContainer);

    // Cleanup
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      stopPolling();
      resizeObserver.disconnect();
    };
  }, [chatContainer, fetchMessages, identifier]);

  const onScrollReachTop = async () => {
    if (hasMoreMessages) {
      setCurrentLimit((prevLimit) => prevLimit + MESSAGE_INCREMENT);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [currentLimit, fetchMessages]);

  const onRequestNewConversation = async () => {
    // remove the wrong session key
    new Cookies().remove(SESSION_KEY);

    generateNewIdentifier();
    setMessages([]);
  };

  const onLoad = async () => {
    console.debug("Initial load, fetching messages from server");
    await fetchMessages();
    (window as any).top.postMessage({ event: "chat:loaded" }, "*");
    console.debug("Chat loaded");
    setIsLoading(false);
  };

  useEffect(() => {
    if (identifier && agentInfo) {
      onLoad();
    }
  }, [identifier, agentInfo]);

  useEffect(() => {
    const spacing = isEmbedded || isMobileDevice() ? 0 : 20;
    setBottomSpacing(spacing);
  }, [isEmbedded]);

  return (
    <div className="flex bg-transparent h-full">
      <div
        className="flex-grow rounded-md h-full"
        ref={(el) => setChatContainer(el)}
      >
        {isLoading ? (
          <LoadingScreen />
        ) : !agentInfo ? (
          <div className="text-center text-gray p-5">Agent is not found.</div>
        ) : (
          <ChatBox
            agentInfo={agentInfo}
            messages={dummyMessage ? [...messages, dummyMessage] : messages}
            onUserMessageAdded={onUserMessageAdded}
            onRequestNewConversation={onRequestNewConversation}
            onScrollReachTop={onScrollReachTop}
          />
        )}
      </div>
    </div>
  );
};

export default Chat;
