import Chat from "../components/Chat";

export default function ChatEmbed() {
  // Extract bot_uuid from query string
  const urlParams = new URLSearchParams(window.location.search);
  const agentUuid = urlParams.get("agentUuid") || "";

  return <div className="fixed inset-0">
    <Chat isEmbedded={true} agentUuid={agentUuid} />
    </div>;
}
