import Chat from "../components/Chat";

import { useParams } from "react-router-dom";

export default function Standalone() {
    let params = useParams();
    // Extract bot_uuid from query string
    const urlParams = new URLSearchParams(window.location.search);
    const agentUuidFromQueryString = urlParams.get("agentUuid");

    let agentUuid: string = params.agentUuid || agentUuidFromQueryString || "";

    return <div className="flex md:p-5 justify-center fixed inset-0">
        <div className="max-w-3xl w-full shadow">
            <Chat isEmbedded={false} agentUuid={agentUuid} />
        </div>
    </div>;
}
