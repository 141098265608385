import { Message } from "../types/Message";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import moment from "moment";
import { isMobileDevice } from "../utils";

const UserMessage = (
  { message }: { message: Message } = { message: {} as Message }
) => {
  const markdownText = message.content?.replace(/\\n/gi, "\n").replace(/\n/gi, "<br/>");

  return (
    <div className="flex justify-end my-2">
      <div className="flex flex-col items-end">
        <div className="flex items-end w-auto bg-blue-500 rounded-xl rounded-br-none max-w-80">
          <div className="p-2">
            <div className={`text-white break-words ${isMobileDevice() ? 'text-base' : 'text-sm'}`}>
              <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>{markdownText + ""}</Markdown>
            </div>
          </div>
        </div>
        <div className="flex items-end w-auto max-w-80">
          <div className={`text-gray-500 pt-1 select-none ${isMobileDevice() ? 'text-sm' : 'text-xs'}`}>
            {moment(message.createdAt).fromNow()}
          </div>
        </div>
      </div>
    </div>
  )
};

export default UserMessage;
